import React, { useState } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import highFidelityImage from '../../../assets/highFidelity.png';
import './highFidelity.css';

function HighFidelity() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container className="high-fidelity-section">
      <Row className="justify-content-center">
        <Col md={12} className="text-center">
          <h2 className="high-fidelity-title">High Fidelity Prototype</h2>
          <p className="high-fidelity-description">
            With our low fidelity prototype refined, it was time to add visual detail and bring the user interface closer to the final product. The high fidelity prototype provides a more precise representation of the user experience, incorporating color, typography, and spacing into the design.
          </p>
          <Image onClick={() => setIsOpen(true)} src={highFidelityImage} alt="High Fidelity Prototype" fluid className="high-fidelity-image" />
          {isOpen && (
            <Lightbox
              mainSrc={highFidelityImage}
              onCloseRequest={() => setIsOpen(false)}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default HighFidelity;
