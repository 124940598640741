import React from 'react';
import { Image } from 'react-bootstrap';
import Introduction from './introduction/introduction';
import bannerImage from '../../assets/habitWaveBanner.png';
import './habitwave.css';
import Personas from './personas/personas';
import Features from './Features/Features';
import Wireframes from './wireframes/Wireframes';
import UsabilityStudy from './UsabilityStudy/UsabilityStudy';
import LowFidelity from './LowFidelity/LowFidelity';
import HighFidelity from './HighFidelity/HighFidelity';
import Mascot from '../Mascots/Mascot';
import Conclusion from './Conclusion/Conclusion';

function HabitWave() {
  return (
    <div className="habit-wave-container">
      <Image src={bannerImage} alt="HabitWave Banner" fluid className="banner-image" />
      <Introduction />
      <Personas />
      <Features />
      <Wireframes />
      <UsabilityStudy />
      <LowFidelity />
      <HighFidelity />
      <Mascot />
      <Conclusion />
      <Image src={bannerImage} alt="HabitWave Banner" fluid className="banner-image" />
    </div>
  );
}

export default HabitWave;
