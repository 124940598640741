import React, { useState } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import './lowFidelity.css';
import lowFidelityImage from '../../../assets/lowFidelity.png'; // Update the path according to your project structure

function LowFidelity() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container className="low-fidelity-section">
      <Row className="justify-content-center">
        <Col md={12} className="text-center">
          <h2 className="low-fidelity-title">Low Fidelity Prototype</h2>
          <p className="low-fidelity-description">
            To begin, the user clicks on the &quot;+&quot; button. After that, they decide on a name for their habit,
            choose an icon, and select a color. They then set the days and times for the habit. Lastly, the first
            screen is refreshed to display the newly established habit.
          </p>
          <Image onClick={() => setIsOpen(true)} src={lowFidelityImage} alt="Low Fidelity Prototype" fluid className="low-fidelity-image" />
          {isOpen && (
            <Lightbox
              mainSrc={lowFidelityImage}
              onCloseRequest={() => setIsOpen(false)}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default LowFidelity;
