import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import './mywork.css';
import workImage1 from '../../assets/mywork1.png';
import workImage2 from '../../assets/mywork2.png';
import workImage3 from '../../assets/mywork3.png';

function MyWork() {
  return (
    <Container id="work" className="work-section">
      <h2 className="work-title">WORK</h2>
      <Row>
        <Col md={6} className="work-image-container">
          <h3 className="work-image-title">Role: UX/UI Design</h3>
          <Link to="/casestudy" className="work-link">
            <img src={workImage1} alt="Work 1" className="work-image" />
          </Link>
        </Col>
        <Col md={6} className="work-image-container">
          <h3 className="work-image-title">Role: Front-end developer</h3>
          <a href="https://dexsolutions.biz" target="_blank" rel="noopener noreferrer" className="work-link">
            <img src={workImage2} alt="Work 2" className="work-image" />
          </a>
        </Col>
        <Col md={6} className="work-image-container">
          <h3 className="work-image-title">Role: Front-end developer</h3>
          <a href="https://codewaveteam.com" target="_blank" rel="noopener noreferrer" className="work-link">
            <img src={workImage3} alt="Work 3" className="work-image" />
          </a>
        </Col>
      </Row>
    </Container>
  );
}

export default MyWork;
