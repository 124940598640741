import React from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Importujte Link
import './navbar.css';
import logo from '../../assets/logo.png';

function CustomNavbar() {
  return (
    <Navbar expand="lg" className="custom-navbar">
      <Navbar.Brand as={Link} to="/" className="navbar-brand">
        <img src={logo} alt="Logo" style={{ height: '50px' }} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <Nav>
          <Link to="/" className="nav-link">Home</Link>
          <Link to="/work" className="nav-link">Work</Link>
          <Link to="/about" className="nav-link">About</Link>
        </Nav>
        <Button variant="dark" as={Link} to="/contact" className="contact-button ms-2">Contact</Button>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default CustomNavbar;
