import React from 'react';
import './header.css';
/* import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons'; */
import { Container } from 'react-bootstrap';
import ja from '../../assets/ja.png';

function Header() {
  return (
    <div className="header">
      <Container fluid>
        <div className="header__container">
          <div className="header__left">
            <div>
              <h1 className="header__title">Hey,</h1>
              <h1 className="header__title">I&apos;m Njegoš, a UX/UI designer </h1>
              <h1 className="header__title">and front-end developer</h1>
              <p className="header__subtitle">Creating fresh, user-friendly designs and snappy, responsive front-ends. I love turning complex problems into simple, beautiful solutions.</p>
            </div>
            <div className="header__actions"> {/* Ovaj div će sada biti na dnu */}
              <a href="#work" className="header__button">See my work</a>
              {/* <FontAwesomeIcon icon={faTwitter} className="header__icon" />
              <FontAwesomeIcon icon={faInstagram} className="header__icon" />
              <FontAwesomeIcon icon={faLinkedinIn} className="header__icon" /> */}
            </div>
          </div>
          <div className="header__right">
            <img src={ja} alt="Njegoš" className="header__image" />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Header;
