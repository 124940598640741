import React, { useState } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import './features.css';
import featuresImage from '../../../assets/features.png';

function Features() {
  const [isOpen, setIsOpen] = useState(false);

  // Handler za otvaranje lightbox-a
  const openLightbox = () => {
    setIsOpen(true);
  };

  return (
    <Container className="features-section">
      <Row className="justify-content-center">
        <Col md={12} className="text-center">
          <h3 className="features-title">Features</h3>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={8} className="text-center">
          <p className="features-introduction">
            After really digging into what users need, I&apos;ve crafted a set of features for
            HabitWave that truly capture the essence of this mission. These features are not just
            cool the&apos;re also based on solid research to help users succeed. Here&apos;s a quick look
            at how HabitWave helps build habits and achieve goals, all in a laid-back and straightforward way.
          </p>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col>
          <Image onClick={openLightbox} src={featuresImage} alt="HabitWave Features" fluid className="features-image" />
          {isOpen && (
            <Lightbox
              mainSrc={featuresImage}
              onCloseRequest={() => setIsOpen(false)}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default Features;
