import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
/* import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'; */
import './contact.css';

function ContactForm() {
  const [form, setForm] = useState({
    from_name: '',
    from_email: '',
    message: '',
  });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .send('service_vrz4y8o', 'template_h3793zq', form, '5D7Y4VbfBuNvw1S74')
      .then(
        (result) => {
          console.log('Email successfully sent!', result.text);
        },
        (error) => {
          console.log('Failed to send email.', error.text);
        },
      );
  };

  return (
    <Container className="my-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <h2 className="text-center mb-4">Let&apos;s Talk</h2>
          <p className="text-muted text-center mb-4">Please fill out the form below, and I&apos;ll get back to you as quickly as possible.</p>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formName">
              <Form.Label>Your Name (Optional)</Form.Label>
              <Form.Control
                type="text"
                name="from_name"
                placeholder="Enter your name"
                value={form.from_name}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Your Email</Form.Label>
              <Form.Control
                type="email"
                name="from_email"
                placeholder="Enter your email"
                required
                value={form.from_email}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formMessage">
              <Form.Label>Your Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="message"
                placeholder="Write your message here"
                required
                value={form.message}
                onChange={handleChange}
              />
            </Form.Group>
            <Button variant="dark" type="submit" className="mt-3" block>Send</Button>
          </Form>
          {/*  <div className="text-center mt-4">
            <FontAwesomeIcon icon={faTwitter} className="social-icon mx-2" />
            <FontAwesomeIcon icon={faInstagram} className="social-icon mx-2" />
            <FontAwesomeIcon icon={faLinkedinIn} className="social-icon mx-2" />
          </div> */}
        </Col>
      </Row>
    </Container>
  );
}

export default ContactForm;
