import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './conclusion.css';

function Conclusion() {
  return (
    <Container className="mascot-container">
      <Row className="justify-content-center">
        <Col md={12}>
          <h2 className="mascot-title">Conclusion</h2>
          <p className="mascot-description">
            Thank you for taking the time to explore the various aspects of the
            HabitWave project with me. While I would have loved to delve deeper
            into some of the more detailed findings from these usability studies,
            I must respect the confidentiality agreements that protect the project details.
          </p>
          <p className="mascot-description">
            Working on HabitWave has been a truly enriching experience. Each phase of the project
            brought its own set of challenges and learnings. From conceptualizing the initial user
            flows to crafting engaging personas like Seabury the Seahorse and Inky the Octopus, every
            step was a chance to apply my Graphic design and UX/UI skills in dynamic ways.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default Conclusion;
