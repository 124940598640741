import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Header, MyWork, Navbar, About, ContactMe, Footer, HabitWave, Contact } from './components';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<><Header /><MyWork /><About /><ContactMe /><Footer /></>} />
          <Route path="/work" element={<MyWork />} />
          <Route path="/about" element={<About />} />
          <Route path="/casestudy" element={<HabitWave />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
