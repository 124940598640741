import React, { useState } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This ensures you have the CSS for Lightbox
import './usabilityStudy.css';
import usabilityStudyImage from '../../../assets/usabilityStudy.png';
import usabilityStudy2Image from '../../../assets/usabilityStudy2.png';

function UsabilityStudy() {
  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const openLightbox = (image) => {
    setCurrentImage(image);
    setIsOpen(true);
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={12} className="text-center">
          <h2 className="usability-study-title">Usability Study</h2>
          <p className="usability-study-introduction">
            With design at a good point, it was crucial to delve into a Usability Study to refine the functionality and ensure a seamless user experience.
          </p>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md={12} className="text-center">
          <p className="usability-study-introduction">
            In case of usability study based around habit creation process I found that moving the &quot;+&quot;
            button to the bottom center of the screen greatly improved accessibility. This change is in line with how people naturally
            use their thumbs on smartphones, making it easier to use and reducing the need for hand movements.
            I also added a streak counter icon that reflects the app&apos;s wave theme that gives a visual boost to keep habits on track.
          </p>
          <Image onClick={() => openLightbox(usabilityStudyImage)} src={usabilityStudyImage} alt="Usability Study" fluid className="usability-study-image" />
          <Image onClick={() => openLightbox(usabilityStudy2Image)} src={usabilityStudy2Image} alt="Usability Study 2" fluid className="usability-study-image" />
        </Col>
      </Row>
      {isOpen && (
        <Lightbox
          mainSrc={currentImage}
          onCloseRequest={() => setIsOpen(false)}
        />
      )}
    </Container>
  );
}

export default UsabilityStudy;
