import React, { useState } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import './mascot.css';
import Mascot1 from '../../assets/Mascot1.png';
import Mascot2 from '../../assets/Mascot2.png';

function Mascot() {
  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const openLightbox = (image) => {
    setCurrentImage(image);
    setIsOpen(true);
  };

  return (
    <Container className="mascot-container">
      <Row className="justify-content-center">
        <Col md={12}>
          <h2 className="mascot-title">Meet your new friends</h2>
          <p className="mascot-description">
            Seabury the Seahorse and Inky the Octopus are here to assist you on your journey to build and maintain positive habits. As friendly navigators of your habit-tracking app, they offer advice and support, making the process not just effective but enjoyable. They are designed to provide a friendly impression and make your app experience feel more personal and engaging.
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Image onClick={() => openLightbox(Mascot1)} src={Mascot1} alt="Seabury the Seahorse" className="mascot-image" />
        </Col>
        <Col md={6}>
          <Image onClick={() => openLightbox(Mascot2)} src={Mascot2} alt="Inky the Octopus" className="mascot-image" />
        </Col>
      </Row>
      {isOpen && (
        <Lightbox
          mainSrc={currentImage}
          onCloseRequest={() => setIsOpen(false)}
        />
      )}
    </Container>
  );
}

export default Mascot;
