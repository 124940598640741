import React, { useState } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import paperWireframesImage from '../../../assets/paperWireframes.png';
import digitalWireframesImage from '../../../assets/digitalWireframes.png';
import './wireframes.css';

function Wireframes() {
  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const openLightbox = (image) => {
    setCurrentImage(image);
    setIsOpen(true);
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={12} className="text-center">
          <h2 className="wireframes-title">Starting the Design</h2>
          <p className="wireframes-introduction">
            After laying a solid foundation with research and feature analysis, the time has come to translate these insights into tangible design solutions.
          </p>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={12} className="text-center">
          <h3 className="wireframes-subtitle">Paper Wireframes</h3>
          <p className="wireframes-description">
            My design process often starts away from the computer, armed with just a sketchpad and pen. This simple setup allows me to quickly iterate and let creativity flow freely, without any digital barriers.
          </p>
          <Image onClick={() => openLightbox(paperWireframesImage)} src={paperWireframesImage} alt="Paper Wireframes" fluid className="paper-wireframes-image" />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={12} className="text-center">
          <h3 className="wireframes-subtitle">Digital Wireframes</h3>
          <p className="wireframes-description">
            The sketches were then meticulously transformed into digital wireframes, setting the stage for detailed design phases and interactive prototypes.
          </p>
          <Image onClick={() => openLightbox(digitalWireframesImage)} src={digitalWireframesImage} alt="Digital Wireframes" fluid className="digital-wireframes-image" />
        </Col>
      </Row>
      {isOpen && (
        <Lightbox
          mainSrc={currentImage}
          onCloseRequest={() => setIsOpen(false)}
        />
      )}
    </Container>
  );
}

export default Wireframes;
