import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { Image } from 'react-bootstrap';
import painPoints from '../../../assets/painPoints.png';
import 'react-image-lightbox/style.css';
import './introduction.css';

function Introduction() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="introduction-container">
      <h1 className="introduction_habitwave_title">HabitWave</h1>
      <p>HabitWave is an all-in-one application designed to assist users in building and maintaining positive habits.</p>
      <ul>
        <li>Set up your desired habits</li>
        <li>Track your progress</li>
        <li>Achieve your goals</li>
      </ul>
      <p>From the outset, the core concept of HabitWave has been to offer a flexible system catering to users&apos; diverse needs and preferences.</p>
      <h2>My role</h2>
      <p>In the HabitWave project, I was involved in several key areas of UX/UI design, including:</p>
      <ul>
        <li>Research and User Surveys</li>
        <li>Graphic Design</li>
        <li>Wireframing and Prototyping</li>
        <li>Usability Testing</li>
        <li>Final Design Implementation</li>
      </ul>
      <h2>Research Goals</h2>
      <p>I aim to understand how users interact with habit-building platforms to enhance HabitWave’s effectiveness in supporting users&apos; efforts to cultivate positive habits.</p>
      <ul>
        <p>Methodologies:</p>
        <li>Competitive Analysis</li>
        <li>User Survey</li>
      </ul>
      <h2 className="h2-lotoftext">Competitive analysis</h2>
      <p>I looke­d at habit apps like Habitica, Habitify, and HabitNow.
        Each app has its own unique strengths. Habitica makes tracking
        habits fun like a game­. Habitify is simple and clean. HabitNow
        lets you customize­ everything. But people­ want more than just tracking.
        They want some fun, to-do lists, and habits customized for the­ir life.
        They also want more than just tips - the­y want a guide to improve their live­s.
        After spotting these gaps I see a chance for HabitWave. It won&apos;t just track
        habits, but will be a journey with insights, growth, and enjoyme­nt.
      </p>
      <h2>User research</h2>
      <h4>Respondent Demographics</h4>
      <p>Total Participants:  Five people were interviewed, providing a focused insight into user habits and app usage preferences.</p>
      <h4>Pain points</h4>
      <Image onClick={() => setIsOpen(true)} src={painPoints} alt="Pain Points" fluid className="pain-point-image" />
      {isOpen && (
        <Lightbox
          mainSrc={painPoints}
          onCloseRequest={() => setIsOpen(false)}
        />
      )}
      <h2 className="h2-user-research">User research: Summary</h2>
      <p>During the user research for HabitWave, I delved deeply into studying user habits,
        behaviors, and preferences. By using qualitative methods like interviews and surveys,
        I gained valuable insights into what drives users, what challenges they face, and what
        goals they have when it comes to forming habits. Initially, I had certain assumptions
        about why users use habit-tracking apps, but my research uncovered a variety of reasons,
        including improving mental health and personal growth. These discoveries gave us direction
        on how to customize HabitWave to better meet the diverse needs and preferences of our users.
      </p>
    </div>
  );
}

export default Introduction;
