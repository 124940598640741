import React from 'react';
import './aboutme.css';
import myPhoto from '../../assets/ja.png'; // Zamijenite putanjom do vaše slike

function AboutMe() {
  return (
    <div>
      <div id="about">
        <h2 className="aboutme-title-header">ABOUT ME</h2>
      </div>
      <div className="about-me-container">
        <div className="about-me-image-section">
          <img src={myPhoto} alt="About Me" className="about-me-image" />
          <div className="about-me-overlay">
            <h1 className="about-me-title">About me</h1>
            <p className="about-me-text">
              My journey into UX/UI design began during my IT studies,
              where I was instantly drawn to the intricate dance of design
              and user interaction. This fascination flourished as I deepened
              my expertise through the <a className="about-me-certificate-link" href="https://coursera.org/share/fc325636ff4b7ec005a4afc3830d2eac" target="_blank" rel="noopener noreferrer"> Google Certificate Program</a> program, subtly
              mastering the art of creating intuitive and user-friendly interfaces.
            </p>
            <p className="about-me-text">
              When I take a break from UX/UI design and all the digital screens,
              I don&apos;t venture too far. Instead, I find relaxation in the engaging world of video games.
              I also enjoy staying active with regular workouts, experimenting
              in the kitchen with new recipes, and losing myself in a good book.
              These are the ways I love to recharge and find joy.
            </p>
            <p className="about-me-text">
              What excites me most about my role is the chance to solve complex problems
              and craft solutions that simplify and enhance everyday life. I thrive on
              designing for a variety of user groups, making sure each interface is as
              appealing as it is functional.
            </p>
            <p className="about-me-text">
              As a UX/UI designer with a keen interest in front-end development,
              my primary goal is to create products that are not only effective but
              also enjoyable to use. I&apos;m committed to continuously refining my skills
              and staying current with the latest trends in design and technology.
              I am passionate about the transformative power of great design and am
              eager to keep contributing to this dynamic and ever-evolving field.
            </p>
          </div>
        </div>
        <div className="about-me-stats-section">
          <div className="about-me-stat green">
            <div className="number">2+</div>
            <div className="description">Years Experience</div>
          </div>
          <div className="about-me-stat yellow">
            <div className="number">30+</div>
            <div className="description">Happy Clients</div>
          </div>
          <div className="about-me-stat red">
            <div className="number">50+</div>
            <div className="description">Successful Projects</div>
          </div>
          <div className="about-me-stat blue">
            <div className="number">100%</div>
            <div className="description">Passion</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutMe;
