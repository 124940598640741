import React from 'react';
import './footer.css';

function Footer() {
  const year = new Date().getFullYear(); // Dinamički dobijamo trenutnu godinu
  return (
    <footer className="footer">
      <p>© {year} Brewed by myself with pixels and code.</p>
    </footer>
  );
}

export default Footer;
