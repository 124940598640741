import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import './contactme.css';

function ContactMe() {
  return (
    <div id="contact" className="contact-me-container">
      <div className="contact-me-content">
        <h2 className="contact-me-title">Got ideas? I&apos;ve got time.</h2>
        <Link to="/contact" className="contact-me-link">
          <Button className="contact-me-button">Contact Me</Button>
        </Link>
      </div>
    </div>
  );
}

export default ContactMe;
