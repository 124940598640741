import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import './personas.css';
import persona1Image from '../../../assets/persona2.png';
import empathyMap from '../../../assets/empathyMap.png';

function Personas() {
  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const handleOpen = (image) => {
    setCurrentImage(image);
    setIsOpen(true);
  };

  return (
    <div className="personas-container">
      <h1>User Persona</h1>
      <p className="subtitle">Based on this user survey, I&apos;ve crafted a persona that encapsulate the characteristics and preferences identified among our respondents.</p>
      <div className="persona-images">
        <Image onClick={() => handleOpen(persona1Image)} src={persona1Image} alt="Persona 1" fluid className="persona-image" />
        <h1>Empathy Map</h1>
        <p className="subtitle">To truly step into users’ shoes, I delve deeper with an empathy map.</p>
        <Image onClick={() => handleOpen(empathyMap)} src={empathyMap} alt="Empathy Map" fluid className="persona-image" />
      </div>
      {isOpen && (
        <Lightbox
          mainSrc={currentImage}
          onCloseRequest={() => setIsOpen(false)}
        />
      )}
    </div>
  );
}

export default Personas;
